var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"810ede1629e7e1ddda86fb90b30478fb9484d52e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN

init({
	dsn: SENTRY_DSN as string,
	tracesSampler: (context) => {
		if (context.parentSampled) return true
		if (context.transactionContext.op === 'pageload' || context.transactionContext.op === 'navigation') return 0.2
		return 0.3
	},
	environment: process.env.VERCEL_ENV as string,
})
